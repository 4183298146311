body {
  background: rgba(5, 5, 5, 0.94);
  background-color: rgba(5, 5, 5, 0.94) !important;
  margin: 0px;
  font-family: "'Sora', sans-serif";
  font-size: "14px";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.displayCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.displayStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.displaySpacebetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
  width: 100%;
  }
  .flexDirectionCol{
    flex-direction: column;
  }
  
.displayEnd {
  display: flex;
  justify-content: end;
  align-items: end;
}

.bg_common {
  background: #171717;
  border-radius: 50px;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
}

.card_common {
  background: #202020;
}

.dispalySpacebetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.display_center {
  display: flex;
  align-items: center;
}

.border_radius_10 {
  border-radius: 10px;
}

.walletContainer {
  border-radius: 10px;
  border: 1px solid #374d69;
}

.textLinearGradient {
  color: #fff !important;
  text-align: center;
  font-size: 25px !important;
  font-weight: 400;
  padding-top: 12px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.cardBox {
  border-radius: 10px;
  background: linear-gradient(132deg, rgb(29, 29, 29) 3.35%, rgb(0, 0, 0) 17.5%, rgb(23, 23, 23) 35.31%, rgb(0, 0, 0) 53.51%, rgb(23, 23, 23) 71.07%, rgb(0, 0, 0) 86.61%, rgba(255, 255, 255, 0.11) 108.07%);
  padding: 15px;
}
.cardBox p {
  font-size: 13px !important;
}
.cardBox .descriptionText {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px !important;
  white-space: pre;
}

.innerCard {
  border-radius: 10px;
  background: #2b3b52;
  padding: 10px;
  overflow: hidden;
}

.tabButton {
  border-radius: 10px;
  padding: 10px 61px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  border: 1px solid rgba(255, 255, 255, 0.08) !important;
  color: #fff !important;
  margin: 0px 5px;
  white-space: pre;
  min-width: 148px !important;
  background: transparent !important;
}

.tabButton.active {
  border: 0px solid transparent !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 15px;
  background: linear-gradient(91deg, #0138fd 5.87%, #13c5f8 103.34%) !important;
}

.alphaBox p {
  font-size: 12px !important;
  color: rgba(255, 255, 255, 0.7019607843);
}

.chatBox {
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
  padding: 8px 15px !important;
}

.alphatextBox p {
  font-size: 12px !important;
  color: rgba(255, 255, 255, 0.7019607843);
}
.alphatextBox .MuiAccordion-root {
  background-color: transparent !important;
  border: none !important;
}
.alphatextBox .MuiAccordionSummary-root.Mui-expanded {
  border-bottom: 1px solid rgb(31, 31, 31) !important;
}
.alphatextBox .Mui-expanded {
  margin: 10px 0;
}

.MuiAccordionSummary-root {
  border-bottom: 1px solid #384c66;
}

.MuiSelect-icon {
  color: #fff !important;
}

.walletborder.lastItem {
  border-bottom: none;
}

.myWaletBox {
  background: linear-gradient(90deg, rgba(204, 204, 204, 0.0784313725) 28.15%, rgba(144, 56, 255, 0) 47.3%, rgba(144, 56, 255, 0) 69.73%), linear-gradient(0deg, hsla(0, 0%, 100%, 0.11), hsla(0, 0%, 100%, 0));
  padding: 1px;
  border-radius: 10px;
}

.homecard {
  background: #202020;
  /* padding: 16px; */
  border-radius: 50%;
  height: 65px;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newCommonBox {
  background: #171717;
  padding: 20px 0;
}
.newCommonBox .MuiSelect-icon {
  color: #fff !important;
}

.commanbackground {
  background: #171717;
  border-radius: 20px 20px 0px 0px;
}

.walletnewcard {
  border-radius: 13px;
  margin: 15px;
  background: #202020;
}

.walletborder {
  border-bottom: 1px solid rgba(128, 128, 128, 0.33);
}

.walletborder.lastItem {
  border-bottom: none;
}

.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(178, 178, 178) !important;
}

.avtarBox {
  background: #202020;
  padding: 7px;
}
.avtarBox .MuiAvatar-img {
  width: auto;
  height: auto;
  max-width: 50px;
}

.MuiDialog-paper {
  margin: 15px !important;
  border-radius: 10px !important;
}/*# sourceMappingURL=main.css.map */

.stopLossContainer{
  align-items: flex-start !important;
  column-gap: 20px;

}

.deleteBtn {
  max-width: 191px;
}
@media (max-width: 600px) {
  .deleteBtn {
    max-width: 80px;
  }
}


.fullWidth{
  width: 100%;
}